import React, { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
function usePersistedState(key, defaultValue) {
    const [state, setState] = React.useState(() =>
        JSON.parse(localStorage.getItem(key)) == null
            ? defaultValue
            : JSON.parse(localStorage.getItem(key))
    );
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}

export default usePersistedState;
