import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const groupReturnSchema = `
    id
    name
    isActive
    locality
    path
    financialYearStartDate
    paymentTerms
    zohoRecordID
    bankBalance
`;

export const GET_GROUPS = gql`
    query GetGroups{
        getGroups {
            ${groupReturnSchema}
        }
    }`;

export default function GetGroups() {
    //executing the query
    const { loading, data, error } = useQuery(GET_GROUPS);

    if (loading) return "LOADING";

    if (!error) return data.getGroups;

    if (error.networkError) {
        throw error.networkError;
    } else {
        error.graphQLErrors.forEach((err) => {
            throw err.message;
        });
    }
}

export const GET_GROUPS_OF_LOGGED_IN_USER = gql`
    query GetGroupsOfLoggedInUser{
        getGroupsOfLoggedInUser {
            ${groupReturnSchema}
        }
    }
`;

export function GetGroupsOfLoggedInUser() {
    //executing the query
    const { loading, data, error } = useQuery(GET_GROUPS_OF_LOGGED_IN_USER);

    if (loading) return "LOADING";

    if (!error) return data.getGroupsOfLoggedInUser;

    if (error.networkError) {
        throw error.networkError;
    } else {
        error.graphQLErrors.forEach((err) => {
            throw err.message;
        });
    }
}

export const ADD_GROUP = gql`
    mutation addGroup($groupIn:GroupIn!){
        addGroup(groupIn:$groupIn) {
            ${groupReturnSchema}
        }
    }
`;

export const EDIT_GROUP = gql`
    mutation editGroup($groupIn:GroupIn!, $id: Int!){
        editGroup(groupIn:$groupIn, id:$id) {
            ${groupReturnSchema}
        }
    }
`;

export const REMOVE_GROUP = gql`
    mutation removeGroup($id: Int!) {
        removeGroup(id: $id)
    }
`;
