import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from "@mui/material";

const displayError = ({ errorMessage, setError, actions = true }) => {
    return (
        <Dialog
            open={!!errorMessage}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Error</DialogTitle>
            <DialogContent
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
                <Typography variant="h4">An issue has occurred.</Typography>
                <Typography variant="h6">
                    Contact the IT team if the issue persists
                </Typography>
                <Typography variant="h6">
                    Please go to another section of the web app or try again
                    later
                </Typography>
                <Divider />
                <Typography variant="h6">Error Message:</Typography>
                <Typography variant="p">{errorMessage}</Typography>
            </DialogContent>
            {actions && (
                <DialogActions>
                    <Button onClick={() => setError("")} color="primary">
                        OK
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export const DisplayError = ({ error, setError = undefined }) => (
    <Dialog
        open={!!error}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent
            style={{ display: "flex", flexDirection: "column", gap: 10 }}
        >
            <Typography variant="h4">An issue has occurred.</Typography>
            <Typography variant="h6">
                Contact the IT team if the issue persists
            </Typography>
            <Typography variant="h6">
                Please go to another section of the web app or try again later
            </Typography>
            <Divider />
            <Typography variant="h6">Error Message:</Typography>
            <Typography variant="p">{error}</Typography>
        </DialogContent>
        {typeof setError === "function" && (
            <DialogActions>
                <Button onClick={() => setError("")} color="primary">
                    OK
                </Button>
            </DialogActions>
        )}
    </Dialog>
);

export default displayError;
